<template>
  <div id="main-page">
    <Header :currentKey='currentKey' :change-key="changeKey"/>
    <img v-show="currentKey!='page5'" class="glide-img" src="@/assets/mobile/header/glide.png" alt="">
    <div class="content-box">
      <!--预约-->
      <div ref="page1" class="page1 pic-bg" :class="['type'+[adTest]]">
        <div class="container">
          <div class="slogan pic-bg">
            <!-- <img @click="videobtn"  class="video" src="@/assets/mobile/1/video.png" alt="视频播放" srcset=""> -->
          </div>
          <div class="download-box" :class="['type'+[adTest]]">
            <a class="android" @click="download('APK','https://wydxz.onelink.me/i52G/cdiqe3eq')" href="javascript:;">
               <img src="@/assets/mobile/1/apk.png" alt="APk"/>
            </a>
            <a class="ios" @click="download('ios','https://trueviewforios.onelink.me/nAvK/ap06hk76')" href="javascript:;">
              <img  src="@/assets/mobile/1/btn.png" alt=""/>
            </a>
          </div>
          <a class="shop" :class="['type'+[adTest]]" @click="download('shop','https://pay.acngames.com/#/game/15')" href="javascript:;" ></a>
          <p  class="activityTime"  data-text='儲值活動時間： 2024年03月30日-2024年04月05日'>儲值活動時間： 2024年03月30日-2024年04月05日</p>
        </div>
      </div>
      <!-- 游戏特色 -->
      <div ref="page5" class="page5 pic-bg" :class="['type'+[adTest]]">
        <div class="container">
            <img class="title" src="@/assets/mobile/5/title_5.png" alt="人物介绍" />
            <div class="characteristics">
              <swiper  class="swiper characteristicsGame" ref="gameSwiper" :options="swiperGameOption">
                <swiper-slide class="characteristicsGameItem"  v-for="item in  swiperData.game" :key="item">
                    <img v-if="adTest !=='3'" class="game" :src="require(`@/assets/mobile/5/game_${item}.png`)">
                    <img v-else class="game" :src="require(`@/assets/mobile/5/game_${item}_.png`)">
                </swiper-slide>
              </swiper>
              <!-- <swiper   class="swiper characteristicsGame" ref="gameSwiper" :options="swiperGameOption">
                <swiper-slide class="characteristicsGameItem"  v-for="item in  swiperData.game1" :key="item">
                    <img class="game" :src="require(`@/assets/mobile/5/game_${item}_.png`)">
                </swiper-slide>
              </swiper> -->
              <!-- 左右箭头 -->
              <div class="swiper-button-prev"  @click='swiperGamePrev'></div>
              <div class="swiper-button-next"  @click='swiperGameNext'></div>
            </div>
            <!-- 审核用 -->
            <img class="footer-img" src="@/assets/mobile/5/footerbgwap.png" alt="" srcset="">
            <!-- <div class="footer pic-bg">
              <img class="acn" src="@/assets/mobile/5/acn.png">
              <div class="footer-box">
                <div class="footer-title">Copyright © 2021 ACNGames.com</div>
                <div class="title-content">
                  <div @click="goToLink(1)">隱私協議</div>
                  <div>｜</div>
                  <a class="email" href="mailto:wydxzcs@gmail.com">聯繫客服</a>
                </div>
              </div>
            </div> -->
        </div>
      </div>
      <!-- <div class="text">
        <img  src="@/assets/mobile/5/text.png" >
      </div> -->
    </div>
    <!-- 视频弹窗 -->
    <div class="ytb_video_bg" v-if="showVideo" @click="doShowVideo" >
      <iframe class="ytb_video" src="https://www.youtube.com/embed/IQzITvqIw3I?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <!-- 视频弹窗 -->
    <!-- <Video-View ref="video" v-if="videoShow" @videotype='videotype' :videoShow='videoShow'></Video-View> -->
  </div>
</template>

<script>
import Header from './header'
// import VideoView from './videoView.vue'
// import { appointRecordDown } from '@/api'
import { getUrlParam } from '@/utils/index'
import { mapState, mapMutations } from 'vuex'
// import { parseTime } from '@/utils'
export default {
  components: { Header },
  data () {
    return {
      adTest: getUrlParam('abtest') || '1',
      // nowIndex: 0,
      list: [
        {
          name: '预约',
          key: 'page1'
        },
        {
          name: '游戏特色',
          key: 'page5'
        }
      ],
      currentKey: 'page1',
      itemOffsetTop: [],
      firstOffsetTop: 0,
      //  人物介绍
      swiperFeatureOption: {
        // 设定初始化时slide的索引
        initialSlide: 0,
        loop: true,
        slidesPerView: 5,
        slideToClickedSlide: true,
        centeredSlides: true,
        on: {
          click () {}
          // slideNextTransitionEnd (swiper) {
          //   console.log(swiper)
          // }
        }
      },
      swiperData: {
        feature: [0, 1, 2, 3, 4, 5, 6, 7],
        game: [0, 1, 2, 3, 4],
        game1: [1, 2, 3, 4]
      },
      featureIndex: 0,
      showFigureAnimate: false,
      // 游戏特色
      swiperGameOption: {
        initialSlide: 0,
        loop: true,
        slidesPerView: 1,
        slideToClickedSlide: true
      },
      supportShow: true, // 显示应援进度
      popupType: 0, // 弹窗类型
      drawTop: true, // 立即抽奖
      fbTd: true, // fb分享没按下状态
      lineTd: true, // line分享没按下状态
      scale: 1,
      imgSrcWh: '', // 小球尺寸
      openLink: [
        'https://line.me/ti/g2/7Ec-1t-Gsm5z4OInIaMYB5N88d2Q3Zd_spFujA?utm_source=invitation&utm_medium=link_copy&utm_campaign=default', // line群
        'http://home.hkpctimes.com/privacy.html', // 隐私政策
        'wydxzcs@gmail.com' // 客服
      ],
      showVideo: false,
      showAnactivie: true
    }
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      this.firstOffsetTop = this.$refs[this.currentKey].offsetTop
      document.addEventListener('scroll', this.onScroll)
      this.list.forEach((val) => {
        this.itemOffsetTop.push({ // 获取高度
          key: val.key,
          num: this.$refs[val.key].offsetTop - this.firstOffsetTop,
          height: this.$refs[val.key].clientHeight
        })
      })
    })
  },
  computed: {
    ...mapState({
      token: state => state.token,
      supportId: state => state.supportId
    })
  },
  methods: {
    ...mapMutations(['SET_USER', 'UPLOAD_EVENT']),
    // 下载
    download (type, url) {
      const type1 = getUrlParam('abtest') || '1'
      if (type === 'APK') {
        if (type1 === '1') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/8FUECM_SiOAYEIPthKUB'
          })
        } else if (type1 === '2') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/l8KwCJzMg-AYEIPthKUB'
          })
        } else {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/qwuSCKOfmOEYEIPthKUB'
          })
        }
        this.UPLOAD_EVENT('dowload_mycard')
      }
      if (type === 'ios') {
        if (type1 === '1') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/h71ACNLSiOAYEIPthKUB'
          })
        } else if (type1 === '2') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/--QtCJ_Mg-AYEIPthKUB'
          })
        } else {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/h89PCKafmOEYEIPthKUB'
          })
        }
        this.UPLOAD_EVENT('dowload_appstore')
      }
      if (type === 'shop') {
        if (type1 === '1') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/32tqCNXSiOAYEIPthKUB'
          })
        } else if (type1 === '2') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/jYkoCKLMg-AYEIPthKUB'
          })
        } else {
          window.gtag('event', 'conversion', {
            send_to: 'AW-346109571/J8dTCKmfmOEYEIPthKUB'
          })
        }
        this.UPLOAD_EVENT('click_web_recharge')
      }
      setTimeout(() => {
        window.open(url)
      }, 200)
    },
    // 滚动监听
    onScroll () {
      const scrollTop = document.documentElement.scrollTop || window.pageYOfset || document.body.scrollTop
      // alert(scrollTop)
      this.itemOffsetTop.forEach((val, index) => {
        if (scrollTop > val.num && scrollTop - val.num > val.height / 2) {
          if (index === this.itemOffsetTop.length - 1) {
            this.currentKey = this.itemOffsetTop[index].key
          } else {
            this.currentKey = this.itemOffsetTop[index + 1].key
          }
        } else if (scrollTop < this.itemOffsetTop[0].height / 2) {
          this.currentKey = this.itemOffsetTop[0].key
        }
      })
    },
    // 点击锚点定位
    changeKey (key) {
      document.removeEventListener('scroll', this.onScroll)
      this.currentKey = key
      const to = this.$refs[key].offsetTop - this.firstOffsetTop
      this.animationScrollTo(document.documentElement, to)
    },
    // 锚点定位动画滚动
    animationScrollTo (el, to) {
      let scrollY = 0
      const beginY = document.documentElement.scrollTop || window.pageYOfset || document.body.scrollTop
      const raf = window.requestAnimationFrame || (func => setTimeout(func, 10))
      const moveFn = () => {
        if (beginY - to < 0) {
          scrollY += (to - beginY) / 30
          if (scrollY <= (to - beginY)) {
            document.documentElement.scrollTop = beginY + scrollY
            document.body.scrollTop = beginY + scrollY
            raf(moveFn)
          } else {
            document.documentElement.scrollTop = to
            document.body.scrollTop = to
            document.addEventListener('scroll', this.onScroll)
          }
        } else if (beginY - to > 0) {
          scrollY += (beginY - to) / 30
          if (scrollY <= (beginY - to)) {
            document.documentElement.scrollTop = beginY - scrollY
            document.body.scrollTop = beginY - scrollY
            raf(moveFn)
          } else {
            document.documentElement.scrollTop = to
            document.body.scrollTop = to
            document.addEventListener('scroll', this.onScroll)
          }
        }
      }
      raf(moveFn)
    },
    // 04人物介绍
    swiperFeaturePrev (swiper) {
      this.$refs.featureSwiper.$swiper.slidePrev()
    },
    swiperFeatureNext (swiper) {
      this.$refs.featureSwiper.$swiper.slideNext()
    },
    featureSlideChange (swiper) {
      // this.$refs.figurePort.classList.remove('figurePort-enter-active')
      // this.$refs.figureInfo.classList.remove('figureInfo-enter-active')
      this.featureIndex = swiper.realIndex
      // this.$nextTick(() => {
      //   this.$refs.figurePort.classList.add('figurePort-enter-active')
      //   this.$refs.figureInfo.classList.add('figureInfo-enter-active')
      // })
    },
    // 05游戏特色
    swiperGameNext () {
      this.$refs.gameSwiper.$swiper.slideNext()
    },
    swiperGamePrev () {
      this.$refs.gameSwiper.$swiper.slidePrev()
    },
    // 播放视频
    videobtn () {
      this.showVideo = true
    },
    doShowVideo () {
      this.showVideo = false
    },
    // 弹窗展示
    showPopup (type) {
      if (type === 1) {
        this.UPLOAD_EVENT('re_register_dialog_view')
      }
      this.$refs.popupview.popupVisible = true
      if (this.$refs.popupview.popupVisible === true) {
        this.showAnactivie = false
      }
      this.popupType = type
    },
    isShow (type) {
      if (this.token) {
        this.showAnactivie = false
      } else {
        this.showAnactivie = true
      }
    },
    // 子组件传过来操作弹窗类型
    nextType (type) {
      this.popupType = type
    },
    // 点击链接
    goToLink (type) {
      if (type === 0) {
        window.gtag('event', 'conversion', {
          send_to: 'AW-346109571/AkrVCLqz064DEIPthKUBs'
        })
        this.UPLOAD_EVENT('click_to_line')
      }
      if (type === 2) {
        document.getElementById('sendMail').click()
      } else {
        window.open(this.openLink[type])
      }
    },
    // 分享
    fbshare () {
      if (this.token) {
        this.UPLOAD_EVENT('share_facebook')
        window.FB && window.FB.ui({
          method: 'share',
          // mobile_iframe: true,
          href: window.location.href
        }, function (response) { })
        this.appointAddShareRecord()
      } else {
        this.showPopup(1) // 填写手机号
      }
    },
    lineshare () {
      if (this.token) {
        window.open(`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(window.location.href)}`)
        // console.log('line分享')
        this.UPLOAD_EVENT('share_line')
        this.appointAddShareRecord()
      } else {
        this.showPopup(1) // 填写手机号
      }
    }
  },
  destroy () {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/mobile/index.scss";
</style>
