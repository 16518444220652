<template>
  <div class="header">
    <div class="nav pic-bg" :class="{'bgcolor':navbtn}">
      <div class="container">
        <div class="link-box">
          <a class="shop pic-bg" @click="goToLink(4)" href="javascript:;" ></a>
          <div @click="goToLink(0)" class="douyin pic-bg"></div>
          <div @click="goToLink(1)" class="youtube pic-bg"></div>
          <div @click="goToLink(2)" class="line pic-bg">
            <div v-show="lineShow" class="go-linecode pic-bg">
              <img class="line-qrcode" src="@/assets/mobile/header/qrcode.png" alt="">
              <div @click="openAlink(2)" class="go-btn pic-bg"></div>
            </div>
          </div>
          <div @click="goToLink(3)" class="facebook pic-bg"></div>
        </div>
        <div class="nav-button pic-bg" @click="navbtn = !navbtn;lineShow=false"></div>
      </div>
    </div>
    <div class="nav-btn" v-show="navbtn">
      <div class="btn" @click="go('page1')">
         <div class="make-btn pic-bg" :class="{is_active: currentKey === 'page1'}"></div>
      </div>
      <div class="btn" @click="go('page5')">
         <div class="feature-btn pic-bg" :class="{is_active:  currentKey === 'page5'}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getUrlParam } from '@/utils/index'
export default {
  props: {
    changeKey: {
      type: Function,
      default: () => {}
    },
    currentKey: {
      type: String,
      default: 'page1'
    }
  },
  mounted () {
    // document.addEventListener('scroll', this.onScroll)
  },
  data () {
    return {
      navbtn: false,
      lineShow: false,
      open: false,
      isScoll: false,
      openLink: [
        'https://www.tiktok.com/@superschoolman',
        'https://www.youtube.com/channel/UCxp87Fgs47GRQwdYozXKYQA',
        'https://line.me/ti/g2/7Ec-1t-Gsm5z4OInIaMYB5N88d2Q3Zd_spFujA?utm_source=invitation&utm_medium=link_copy&utm_campaign=default',
        'https://www.facebook.com/wdxzcyd',
        'https://pay.acngames.com/#/game/15'
      ]
    }
  },
  methods: {
    ...mapMutations(['UPLOAD_EVENT']),
    // // 滚动监听
    // onScroll () {
    //   const scrollTop = document.documentElement.scrollTop || window.pageYOfset || document.body.scrollTop
    //   if (scrollTop) {
    //     this.isScoll = true
    //   } else {
    //     this.isScoll = false
    //   }
    //   console.log(this.isScoll)
    // },
    goToLink (type) {
      this.navbtn = false
      if (!this.lineShow) {
        this.UPLOAD_EVENT('line_dialog_view')
      }
      if (type === 2) {
        this.lineShow = !this.lineShow
      } else {
        this.lineShow = false
        this.openAlink(type)
      }
    },
    go (i) {
      this.changeKey(i)
      this.navbtn = false
    },
    // 前往Line群
    openAlink (type) {
      const type1 = getUrlParam('abtest') || '1'
      switch (type) {
        case 0:
          this.UPLOAD_EVENT('click_to_tiktok')
          break
        case 1:
          this.UPLOAD_EVENT('click_to_youtube')
          break
        case 2:
          // window.gtag('event', 'conversion', {
          //   send_to: 'AW-346109571/AkrVCLqz064DEIPthKUBs'
          // })
          this.UPLOAD_EVENT('click_to_line')
          break
        case 3:
          this.UPLOAD_EVENT('click_to_facebook')
          break
        case 4:
          if (type1 === '1') {
            window.gtag('event', 'conversion', {
              send_to: 'AW-346109571/32tqCNXSiOAYEIPthKUB'
            })
          } else if (type1 === '2') {
            window.gtag('event', 'conversion', {
              send_to: 'AW-346109571/jYkoCKLMg-AYEIPthKUB'
            })
          } else {
            window.gtag('event', 'conversion', {
              send_to: 'AW-346109571/J8dTCKmfmOEYEIPthKUB'
            })
          }
          this.UPLOAD_EVENT('click_web_recharge')
          break
        default:
      }
      setTimeout(() => {
        window.open(this.openLink[type])
      }, 200)
    }
  }
  // watch: {
  //   navbtn: {
  //     immediate: true, // true首次加载执行，否则不执行为false或不添加此属性
  //     handler (newValue, oldValue) {
  //       if (newValue === true) {
  //         setTimeout(() => {
  //           this.navbtn = false
  //         }, 2000)
  //       }
  //       // console.log('首次触发', newValue, oldValue)
  //     }
  //   }
  // }

}
</script>

<style lang="scss" scoped>
@import "@/styles/mobile/header.scss";
</style>
